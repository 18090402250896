import React, { useState } from "react";

const Hamburger = (props) => {
  const {
    id,
    onOpen,
    onClose,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const toggleOpening = (open) => {
    setOpen(!open);
    if (!open) {
      onOpen();
    } else {
      onClose();
    }
  };
  return (
    <button
      id={ id }
      onClick={ () => { toggleOpening(isOpen); } }
      className={ isOpen ? "open" : "closed" }
    >
      <span className="sr-only">Menu { isOpen ? "ouvert":"fermé" }</span>
    </button>
  );
};
export default Hamburger;
