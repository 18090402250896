import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import decodeHTMLEntities from "../../utils/decodeHTMLentities.js";

const Logo = (props) => {
  const {
    logo, title
  } = props;
  return (
    <Link to="/" title={ "Retour à l'accueil du site "+decodeHTMLEntities(title) }>
      <GatsbyImage image={ getImage(logo) } alt={ "Retour à l'accueil du site "+decodeHTMLEntities(title) } />
    </Link>
  );
};
export default Logo;
