import React, { useState } from "react";
import Link from "./link.js";

const MenuItem = (props) => {
  const {
    path,
    label,
    databaseId,
    children
  } = props;
  const [dropDownState, setDropDown] = useState(false);
  return (
    <li
      className={ props.containerClassName }
      onMouseOver={ () => { if (props.dropdown === "hover") { setDropDown(true); } } }
      onMouseOut={ () => { if (props.dropdown === "hover") { setDropDown(false); } } }
      onClick={ () => { if (props.dropdown === "click") { setDropDown(!dropDownState); } } }
      onKeyDown={ (e) => {
        if (!children.length) {
          return;
        }
        if (e.keyCode === 13) { // Enter
          e.stopPropagation();
          setDropDown(!dropDownState);
        }
        if (e.keyCode === 27) { // Escape
          if (dropDownState) {
            e.stopPropagation();
          }
          setDropDown(false);
        }
      }}>
      <Link
        id={ "menu-item-"+databaseId }
        to={ path }
        itemProp="url"
        activeClassName="active"
        aria-expanded={ children.length ? dropDownState : undefined }
        aria-controls={ children.length ? "dropdown-"+databaseId : undefined }
        title={ label }>
        { label }
      </Link>
      {
        children.length ?
          (<ul
            role="menu"
            aria-labelledby={ "menu-item-"+databaseId }
            aria-hidden={ !dropDownState }
            id={ "dropdown-"+databaseId }
            tabIndex="-1">
            { children.map(item => (
              <MenuItem
                key={item.id}
                path={item.path}
                label={item.label}
                id={item.id}
                databaseId={item.databaseId}
                children={item.children} // eslint-disable-line
                dropdown={props.dropdown}
              />
            ))
            }
          </ul>)
          : (<></>)
      }
    </li>);
};

export default MenuItem;
