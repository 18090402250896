import React from "react";

const QuickLinks = () => {
  return (
    <nav id="accessLinks" role="navigation" aria-label="Liens d'évitement">
      <p className="sr-only">Aller à :</p>
      <ul>
        <li><a href="#main" title="Accéder au contenu" tabIndex="1">Contenu</a></li>
        <li><a href="#mainNav" title="Accéder au menu principal" tabIndex="2">Menu principal</a></li>
      </ul>
    </nav>
  );
};
export default QuickLinks;
